import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import cookies from "js-cookie";
import { removeAllCookies } from "../utils";
import constants from '../utils/constants';
import { CookieType } from "../utils/types";

const client = axios.create({
  baseURL: constants.API_URL,
});

client.interceptors.request.use(
  config => {
    const token = cookies.get(CookieType.TOKEN);
    if (config.headers) {
      if (token) config.headers['access-token'] = token;
    }
    return config;
  }, error => Promise.reject(error)
);

const request = async (options: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse) => {
    if (constants.isDev) console.log("Request Successful!", response);
    return response.data;
  };

  const onError = (error: AxiosError) => {
    if (constants.isDev) console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      if (constants.isDev) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
        console.error("Headers:", error.response.headers);
      }

      // The user is unauthorized
      if (error.response.status === 401 || error.response.status === 403) {
        removeAllCookies();
        window.location.href = "/login";
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      if (constants.isDev) console.error("Error Message:", error.message);
    }
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;