import { BaseProvider, LightTheme } from 'baseui';
import { SnackbarProvider } from 'baseui/snackbar';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import AuthenticationMiddleware from './components/AuthenticationMiddleware';
import ProtectedRoute from './components/ProtectedRoute';
import { ROUTES } from './models/types';
import Root from './pages';
import Dashboard from './pages/dashboard';
import Login from './pages/login';

const engine = new Styletron();
const App = () => {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <Application />
      </BaseProvider>
    </StyletronProvider>
  )
}

const Application = () => {
  return (
    <AuthenticationMiddleware>
      <SnackbarProvider>
        <BrowserRouter>
          <Routes>
            <Route path={ROUTES.ROOT} element={<Root />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.DASHBOARD} element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="*" element={<Navigate to={ROUTES.LOGIN} replace />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </AuthenticationMiddleware >
  )
}

export default App