import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../models/types';
import { baseUserAtom } from '../store/userStore';

const Root = () => {
    const navigate = useNavigate();
    const user = useAtomValue(baseUserAtom)

    /*
    * Checks if the user is allowed to access the application
    */
    useEffect(() => {
        if (user) navigate(ROUTES.DASHBOARD)
        else navigate(ROUTES.LOGIN)
    }, [user, navigate]);

    return null
}

export default Root