
import {
  AppNavBar,
  setItemActive
} from "baseui/app-nav-bar";
import {
  ChevronDown,
  Delete,
  Overflow,
  Upload
} from "baseui/icon";
import { useState } from "react";

enum NavigationOptions {
  Users = 'Users',
  Settings = 'Settings'
}

const Header = () => {

  const [mainItems, setMainItems] = useState([
    { icon: Upload, label: NavigationOptions.Users },
    {
      active: true,
      icon: ChevronDown,
      label: NavigationOptions.Settings,
      navExitIcon: Delete,
      children: [
        { icon: Upload, label: "Secondary A" },
        { icon: Upload, label: "Secondary B" },
      ]
    }
  ]);


  return (
    <AppNavBar
      title="Strealer"
      mainItems={mainItems}
      onMainItemSelect={item => {
        // @ts-ignore
        setMainItems(prev => setItemActive(prev, item));
      }}
      username="Umka Marshmallow"
      usernameSubtitle="Top Executive"
      userItems={[
        { icon: Overflow, label: "User A" },
        { icon: Overflow, label: "User B" }
      ]}
      onUserItemSelect={item => console.log(item)}
    />
  );
}

export default Header;