import { useStyletron } from 'baseui';
import { ReactNode } from 'react';

const Container = ({children}: { children: ReactNode}) => {
    const [css] = useStyletron();
    return (
        <div className={css({
            margin: '0 auto',
            padding: '0 15px',
            maxWidth: '1440px',
        })}>{children}</div>
    )
}

export default Container