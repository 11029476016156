import { Button } from 'baseui/button'
import React from 'react'
import Header from '../../components/Header'
import Container from '../../components/UI/Container'
import UserService from '../../services/api/user'

const Dashboard = () => {
  return (
    <div>
        <Header />
        <Container>
            <Button onClick={() => UserService.revokeSession()}>Logout</Button>
            Dashboard content
        </Container>
    </div>
  )
}

export default Dashboard