import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { useSnackbar } from 'baseui/snackbar';
import { DisplaySmall } from "baseui/typography";
import { useAtom, useAtomValue } from 'jotai';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../models/types';
import UserService, { type LoginFields } from '../services/api/user';
import constants from '../shared/utils/constants';
import { CookieType } from '../shared/utils/types';
import { baseUserAtom } from '../store/userStore';

type FormFieldError = {
    field: string;
    message: string;
}

const Login = () => {
    const [fields, setFields] = useState<LoginFields>({});
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<FormFieldError[]>([]);
    const user = useAtomValue(baseUserAtom);
    const navigate = useNavigate();
    const {enqueue} = useSnackbar();

    const isError = (field: string) => {
        return errors.some((e: FormFieldError) => e.field === field);
    }

    const getErrorMessage = (field: string) => {
        const error = errors.find((e: FormFieldError) => e.field === field);
        return error ? error.message : '';
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        setFields((p: LoginFields) => ({ ...p, [name]: value }));
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors([]);
        try {
            const response = await UserService.login({
                username: fields.username,
                password: fields.password
            });
            if ('access_token' in response) {
                if (constants.isDev) console.log('Setting token', response.access_token, 'to cookie', CookieType.TOKEN);
                Cookies.set(CookieType.TOKEN, response.access_token, {
                    path: "/",
                    expires: 1 // expires 1 day
                });
            }
            setIsLoading(false);
            // Navigate to root, so that the user will be fetched with the token
            // TODO: Fix this, for some reason the user is not redirected to the dashboard with new state
            window.location.reload();
        } catch (err) {
            setIsLoading(false);
            if ('data' in (err as any)) setErrors((err as any).data as FormFieldError[]);
            else {
                // TODO: Send event to sentry
                enqueue({
                    message: 'Something went wrong. Please try again later.',
                }, 5000)
            }
        }
    }

    // If the user is present, redirect them to the dashboard
    useEffect(() => {
        if (user) navigate(ROUTES.DASHBOARD);
    }, [user, navigate]);

    return (
        <Block
            width="100vw"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Block margin="0 auto" width="320px">
                <DisplaySmall marginBottom="16px">Login</DisplaySmall>
                <form onSubmit={handleSubmit}>
                    <FormControl
                        htmlFor="username"
                        label={() => "Username"}
                        disabled={isLoading}
                        error={isError('username') && getErrorMessage('username')}
                    >
                        <Input
                            name="username"
                            id="username"
                            value={fields?.username ?? ""}
                            autoComplete="email"
                            error={isError('username')}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl
                        htmlFor="password"
                        label={() => "Password"}
                        disabled={isLoading}
                        error={isError('password') && getErrorMessage('password')}
                    >
                        <Input
                            name="password"
                            id="password"
                            type="password"
                            value={fields?.password ?? ""}
                            error={isError('password')}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <Button isLoading={isLoading} type="submit">
                        Login
                    </Button>
                </form>
            </Block>
        </Block>
    )
}

export default Login