import { useAtomValue } from 'jotai';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from '../models/types';
import { baseUserAtom } from '../store/userStore';

/*
* Checks if the user is allowed to access the route
*/
const ProtectedRoute = ({ children }) => {
    const user = useAtomValue(baseUserAtom)
    if (!user) return <Navigate to={ROUTES.LOGIN} replace />;

    return children ? children : <Outlet />;
};

export default ProtectedRoute