import { useAtom } from 'jotai';
import cookies from 'js-cookie';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IUser } from '../models/IUser';
import UserService from '../services/api/user';
import { CookieType } from '../shared/utils/types';
import { baseUserAtom } from '../store/userStore';
import FullscreenLoader from './FullscreenLoader';

/* 
    This component is used to wrap the entire application.
    It checks the authentication status of the user
    If the user has valid session, they will be allowed to access the application
    Otherwise they will be prompted to login (through <ProtectedRoute />)
*/

const AuthenticationMiddleware = ({ children }: { children: any}) => {
    const [user, setUser] = useAtom(baseUserAtom);
    const [isUserLoaded, setIsUserLoaded] = useState(user ? true : false);
    const token = useMemo(() => cookies.get(CookieType.TOKEN), []);

    const getUserWithToken = useCallback(async () => {
        try {
            const data: IUser = await UserService.getUser(token as string)
            setUser(data);
            setIsUserLoaded(true);
        } catch (err) {
            setIsUserLoaded(true);
        }
    }, [setUser, token]);

    useEffect(() => {
        if (token && !user) getUserWithToken();
        else setIsUserLoaded(true);
    }, [user, token, getUserWithToken]);

    if (isUserLoaded) return children;

    return <FullscreenLoader text="Setting everything up..." />
}

export default AuthenticationMiddleware