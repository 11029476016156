import request from "../../shared/lib/request";
import { removeAllCookies } from "../../shared/utils";

/*
 * Gets the user
 */

const getUser = async (access_token: string) => {
  return await request({
    url: `/session/user`,
    method: "GET",
  });
}

/*
 * Tries to log in the user
 */

const login = async (fields: LoginFields) => {
  return await request({
    url: `/auth/login`,
    method: "POST",
    headers: {
      ...fields
    }
  });
}

/*
 * Revokes the authentication session
 */

const revokeSession = async () => {
  // TODO: Should also revoke session from backend
  /*   const response = await request({
      url: `/session/user`,
      method: "DELETE"
    }); */
  removeAllCookies();
  window.location.reload();

  //return response;
}


const UserService = {
  getUser,
  login,
  revokeSession
};

export type LoginFields = {
  username?: string;
  password?: string;
}

export default UserService;